body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.mt10{
  margin-top: 10px;
}
.cbgcolor{
  background:#aa12ce ;
    color: white;
    font-weight: bold;
}
.cbgcolor2{
  background:#0c010fa4 ;
    color: white;
    font-weight: bold;
}
.cbgcolor:hover{
  color: yellow;
}
.mt90{
margin-top: 90px;
}
.taj{
  text-align: justify;
}
.tac{
  text-align: center;
}
.tar
{
  text-align: end;
}
.servicetext{
  color: linear-gradient(90deg, rgba(214,21,99,1) 3%, rgba(195,12,197,1) 49%, rgba(255,0,91,1) 88%);;
  /* text-align: center; */
  color: rgb(214,21,99);

}
.aboutusbg
  {
    color: #ffffff;
    text-align: center;
    background: rgb(180,21,214);
    background: linear-gradient(90deg, rgba(180,21,214,1) 3%, rgba(197,12,97,1) 49%, rgba(243,0,255,1) 88%);
  }
.aboutusbg2
{
  color: #ffffff;
  text-align: center;
  background: rgb(186,21,214);
background: linear-gradient(90deg, rgba(186,21,214,1) 6%, rgba(83,12,197,1) 49%, rgba(255,0,232,1) 98%);
}
.servicetext2{
  color: rgb(186,21,214);
  color: linear-gradient(90deg, rgba(186,21,214,1) 6%, rgba(83,12,197,1) 49%, rgba(255,0,232,1) 98%);
  
}
.textccolor{
  color: #EB6700;
}
.contactusbg
{
  color: #ffffff;
  text-align: center;
  background: rgb(172,12,130);
background: linear-gradient(90deg, rgba(172,12,130,1) 8%, rgba(197,12,75,1) 49%, rgba(170,8,107,1) 85%);
}
.navbarmenutext{
  color: linear-gradient(90deg, rgba(201,14,152,1) 8%, rgba(158,10,158,1) 49%, rgba(204,13,204,1) 94%);
}
.c-item{
    height: 480px;
  }
  .c-img{
    height: 100%;
    object-fit: cover;
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
  
  }
  
  

  /*contact us css*/
  /* === BASE HEADING === */ 
  
  .one h4 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 30px;
    color: #080808;
    color: rgb(172,12,130);
    color: linear-gradient(90deg, rgba(172,12,130,1) 8%, rgba(197,12,75,1) 49%, rgba(170,8,107,1) 85%);
    transition: all 0.4s ease 0s;
  }
  
  
  
  /* === HEADING STYLE #1 === */
  .one h4 {
    text-align: center;
    
    padding-bottom: 3px;
  }
  .one h4::before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .one h4::after {
    width: 250px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -121px;
    background-color: #b80000;
  }
  
  .mb-80{
    margin-top: 0px;
  }
  .mb-80{
    margin-top: 0px;
  }
  
  
  
  .contact-form  {
    height: 60px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 0 0;
    color: #3a4a56;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 0 15px;
    margin-bottom: 30px;
    border-radius: 6px;
    
  }
  
  
  .getintouch img {
   
   height: 100%;
   width: 100%;
   vertical-align: middle;
  }
  .contactcard { 
    transition: -webkit-transform 0.8s; 
    transition: transform 0.8s; 
    transition: transform 0.8s, -webkit-transform 0.8s; 
  } 
  
  .contactcard:hover { 
   
    background: rgb(172,12,130);
background: linear-gradient(90deg, rgba(172,12,130,1) 8%, rgba(197,12,75,1) 49%, rgba(170,8,107,1) 85%);

  color: #ffffff; 
  -webkit-transform: scale(1.1); 
          transform: scale(1.1); 
  z-index: 9; 
  } 
  .address_info:hover{
    color:white;
  }
  .contactcard a
  {
    color:black
  }
  .contactcard a:hover
  {
    color: white;
  }
  .navtextcolor
   {
    color: #800080;
   }

.navtextalign{
    margin-left: 100px;
}
.navtextalign:hover{
    /* text-decoration: underline; */
    transition: color 0.3s ease, -webkit-text-decoration 0.3s ease;
    transition: color 0.3s ease, text-decoration 0.3s ease;
    transition: color 0.3s ease, text-decoration 0.3s ease, -webkit-text-decoration 0.3s ease; /* Smooth transition */
    background-color: #800080;
    border-radius: 20px;
    color: white !important;
}
.navtextalign span:hover{
    /* text-decoration: underline; */
    transition: color 0.3s ease, -webkit-text-decoration 0.3s ease;
    transition: color 0.3s ease, text-decoration 0.3s ease;
    transition: color 0.3s ease, text-decoration 0.3s ease, -webkit-text-decoration 0.3s ease; /* Smooth transition */
    background-color: #800080;
  color: white !important;

}
@media only screen and (max-width: 600px) {
    .ma {
         margin-top: 200px;
    }
  }
  .servicebg{
     color: #ffffff;
     text-align: center;
     background: rgb(214,21,99);
 background: linear-gradient(90deg, rgba(214,21,99,1) 3%, rgba(195,12,197,1) 49%, rgba(255,0,91,1) 88%);
   }
   

        .cl1 img{
            height: 100%;
            width: 100%;
         
            }
           
          .cl1  {
            position: relative;
            text-align: center;
            color: white;
           
             }

          .b1 {
            position: absolute;
            bottom: 150px;
            left: 300px;
          }
      
            .ctext{
              size: 2rem;
              font-weight: 500;
            }
            /* === BASE HEADING === */ 

h2 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  transition: all 0.4s ease 0s;
}



/* === HEADING STYLE #1 === */
.one h2 {
  text-align: center;
  
  padding-bottom: 5px;
}
.one h2::before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h2::after {
  width: 300px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -150px;
  background-color: #b80000;
}
.careerbgcolor{
  /* background: rgb(180,21,214);
background: linear-gradient(90deg, rgba(180,21,214,1) 0%, rgba(197,12,97,1) 41%, rgba(243,0,255,1) 100%);
color: #ffffff; 
font-weight: bold; */
background-image: linear-gradient(to bottom right, rgb(95, 211, 224), rgb(10, 89, 93));
}
.bgcolor{
    background: #3f51b5;
}
.tcw{
    color: white;
}
.mt70{
    margin-top: 70px;
}

.mt100{
    margin-top: 100px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: var(--black);
    line-height: 1.4;
}
.bgcolor{
    background: #3f51b5;
}
.tcw{
    color: white;
}
.mt70{
    margin-top: 70px;
}
.mt100{
    margin-top: 100px;
}

.list_img {
    content:  url(data:image/gif;base64,R0lGODlhCQAJAPcAADMzM8vLy2ZmZpiYmAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/h1HaWZCdWlsZGVyIDAuMiBieSBZdmVzIFBpZ3VldAAh+QQECgD/ACwAAAAACQAJAEcIKgABCBw4cIDBgwYFBFgYAICAhwobEny4ECFEiQQdMhx4seIAiAoRJhQQEAAh+QQECgD/ACwAAAAACQAJAEcIKwAHBBhIMAAAAQgTIgQwoOEAABAhOiQYsaHCigILSpwYkaFFAR0HKFwIICAAIfkEBAoA/wAsAAAAAAkACQBHCCsABQwYSHAggIMIEQpYKABAgIcBGBZ8uDChw4gCCw7AuHAgRIYIP1o8GCAgACH5BAQKAP8ALAAAAAAJAAkARwgqAAEIGEhwIIAACBMGGACgYcMBEBk2LAjRYcKIEwsKkJgRo8WIEhUuHBAQACH5BAQKAP8ALAAAAAAJAAkARwgqAAEIHDhwgMGDBgUEWBgAgICHChsSfLgQIUSJBB0yHHix4gCIChEmFBAQADs=);
    margin-right: 10px;
  text-indent: -9999px;
  }
.mt100{
    margin-top: 150px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: var(--black);
    line-height: 1.4;
}
.list-decorate{
    list-style-type: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.mt10{
  margin-top: 10px;
}
.cbgcolor{
  background:#aa12ce ;
    color: white;
    font-weight: bold;
}
.cbgcolor2{
  background:#0c010fa4 ;
    color: white;
    font-weight: bold;
}
.cbgcolor:hover{
  color: yellow;
}
.mt90{
margin-top: 90px;
}
.taj{
  text-align: justify;
}
.tac{
  text-align: center;
}
.tar
{
  text-align: end;
}
.servicetext{
  color: linear-gradient(90deg, rgba(214,21,99,1) 3%, rgba(195,12,197,1) 49%, rgba(255,0,91,1) 88%);;
  /* text-align: center; */
  color: rgb(214,21,99);

}
.aboutusbg
  {
    color: #ffffff;
    text-align: center;
    background: rgb(180,21,214);
    background: linear-gradient(90deg, rgba(180,21,214,1) 3%, rgba(197,12,97,1) 49%, rgba(243,0,255,1) 88%);
  }
.aboutusbg2
{
  color: #ffffff;
  text-align: center;
  background: rgb(186,21,214);
background: linear-gradient(90deg, rgba(186,21,214,1) 6%, rgba(83,12,197,1) 49%, rgba(255,0,232,1) 98%);
}
.servicetext2{
  color: rgb(186,21,214);
  color: linear-gradient(90deg, rgba(186,21,214,1) 6%, rgba(83,12,197,1) 49%, rgba(255,0,232,1) 98%);
  
}
.textccolor{
  color: #EB6700;
}
.contactusbg
{
  color: #ffffff;
  text-align: center;
  background: rgb(172,12,130);
background: linear-gradient(90deg, rgba(172,12,130,1) 8%, rgba(197,12,75,1) 49%, rgba(170,8,107,1) 85%);
}
.navbarmenutext{
  color: linear-gradient(90deg, rgba(201,14,152,1) 8%, rgba(158,10,158,1) 49%, rgba(204,13,204,1) 94%);
}
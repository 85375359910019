.mt100{
    margin-top: 150px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: var(--black);
    line-height: 1.4;
}
.list-decorate{
    list-style-type: none;
}

  /*contact us css*/
  /* === BASE HEADING === */ 
  
  .one h4 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 30px;
    color: #080808;
    color: rgb(172,12,130);
    color: linear-gradient(90deg, rgba(172,12,130,1) 8%, rgba(197,12,75,1) 49%, rgba(170,8,107,1) 85%);
    
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  
  /* === HEADING STYLE #1 === */
  .one h4 {
    text-align: center;
    
    padding-bottom: 3px;
  }
  .one h4::before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .one h4::after {
    width: 250px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -121px;
    background-color: #b80000;
  }
  
  .mb-80{
    margin-top: 0px;
  }
  .mb-80{
    margin-top: 0px;
  }
  
  
  
  .contact-form  {
    height: 60px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 0 0;
    color: #3a4a56;
    font-size: 1.1rem;
    font-weight: 400;
    padding: 0 15px;
    margin-bottom: 30px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    
  }
  
  
  .getintouch img {
   
   height: 100%;
   width: 100%;
   vertical-align: middle;
  }
  .contactcard { 
    transition: transform 0.8s; 
  } 
  
  .contactcard:hover { 
   
    background: rgb(172,12,130);
background: linear-gradient(90deg, rgba(172,12,130,1) 8%, rgba(197,12,75,1) 49%, rgba(170,8,107,1) 85%);

  color: #ffffff; 
  transform: scale(1.1); 
  z-index: 9; 
  } 
  .address_info:hover{
    color:white;
  }
  .contactcard a
  {
    color:black
  }
  .contactcard a:hover
  {
    color: white;
  }
  .navtextcolor
   {
    color: #800080;
   }
.bgcolor{
    background: #3f51b5;
}
.tcw{
    color: white;
}
.mt70{
    margin-top: 70px;
}
.mt100{
    margin-top: 100px;
}

.list_img {
    content:  url('../bullet2.gif');
    margin-right: 10px;
  text-indent: -9999px;
  }
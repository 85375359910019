.c-item{
    height: 480px;
  }
  .c-img{
    height: 100%;
    object-fit: cover;
    filter: brightness(0.6);
  
  }
  
  

        .cl1 img{
            height: 100%;
            width: 100%;
         
            }
           
          .cl1  {
            position: relative;
            text-align: center;
            color: white;
           
             }

          .b1 {
            position: absolute;
            bottom: 150px;
            left: 300px;
          }
      
            .ctext{
              size: 2rem;
              font-weight: 500;
            }
            /* === BASE HEADING === */ 

h2 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}



/* === HEADING STYLE #1 === */
.one h2 {
  text-align: center;
  
  padding-bottom: 5px;
}
.one h2::before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h2::after {
  width: 300px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -150px;
  background-color: #b80000;
}
.careerbgcolor{
  /* background: rgb(180,21,214);
background: linear-gradient(90deg, rgba(180,21,214,1) 0%, rgba(197,12,97,1) 41%, rgba(243,0,255,1) 100%);
color: #ffffff; 
font-weight: bold; */
background-image: linear-gradient(to bottom right, rgb(95, 211, 224), rgb(10, 89, 93));
}
@media only screen and (max-width: 600px) {
    .ma {
         margin-top: 200px;
    }
  }
  .servicebg{
     color: #ffffff;
     text-align: center;
     background: rgb(214,21,99);
 background: linear-gradient(90deg, rgba(214,21,99,1) 3%, rgba(195,12,197,1) 49%, rgba(255,0,91,1) 88%);
   }
   
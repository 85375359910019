.navtextalign{
    margin-left: 100px;
}
.navtextalign:hover{
    /* text-decoration: underline; */
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition */
    background-color: #800080;
    border-radius: 20px;
    color: white !important;
}
.navtextalign span:hover{
    /* text-decoration: underline; */
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition */
    background-color: #800080;
  color: white !important;

}